<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索して対象を指定</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-maxlength
                                  maxlength="41"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="email"
                                  v-email
                                  v-trim
                                  v-maxlength
                                  maxlength="50"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>電話番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  name="telephone"
                                  v-trim
                                  v-number
                                  v-maxlength
                                  maxlength="14"
                                  v-model="searchForm.telephone"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="number"
                                  v-trim
                                  v-model="searchForm.number"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearchCurrency">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="submitSearchCurrency">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:headline>CSVで対象を指定</template>
              <template v-slot:body>
                <div class="d-flex justify-content-center align-items-center">
                  <ActionButton class="btn btn-lg btn-black" :disabled="isUploadCsvDisabled" :handle-submit="uploadCsv" button-text="CSVアップロード" />
                  <input
                    type="file"
                    style="display: none"
                    ref="csvInput"
                    accept=".csv"
                    @change="onCsvFilePicked"
                  />
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :checkedKey="checkedKey"
                    :data-table="dataTable"
                    :labels="labels"
                    @select-items="handleSelectedItems"
                    :count="customerCount"
                    ref="table"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  v-if="childEventDetail.id > 0"
                  ref="pagination"
                  :listLength="customerCount"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :searchConditions="searchConditions"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item" v-if="(hasShopRole || useTicketFlag || !hasGroup)">
                <button class="btn btn-red" type="button" :disabled="isPaymentDisabled" @click="handeClickShowModal(paymentType)">利用する</button>
              </li>
              <li class="listGrid-item">
                <button class="btn btn-main" type="button" :disabled="isChargeDisabled" @click="handeClickShowModal(chargeType)">付与する</button>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <CurrencyModal
      ref="modal"
      :type='type'
      :target='selectedUsers'
      :balance="balance"
      :searchConditions="searchForm"
      :currentChildCompany="currentChildCompany"
      :eventId="childEventDetail.id"
      :totalUserSelected="totalUserSelected"
      @checkImportStatus="checkImportStatus"
      @setImportingCurrency="setImportingCurrency"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Encoding from 'encoding-japanese';
//components
import FormRow from '@/components/FormRow.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import CurrencyModal from '@/views/Event/Currency/Form.vue';
//mixins
import nav from '@/mixins/nav/currency';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
//helpers
import { formatLocalString, formatDateAndTime, breakLineWithComma, endline } from '@/helpers/formatData';
import { checkFileSize, checkCsvFile } from '@/helpers/file';
//constants
import { EventConstants } from '@/constants/event';
import { FileConstants } from '@/constants/file';
import { StatusConstants } from '@/constants/status';

export default {
  name: 'EventCurrency',
  data: function() {
    return {
      pageName: '通貨管理',
      type: '',
      searchForm: {},
      modulePath: 'customer/getCustomerList',
      searchConditions: {},
      initialSearchConditions: {
        validFlag: true,
        directory: this.$route.params.directory,
      },
      storeModule: 'customer',
      searchFields: ['name', 'email', 'telephone', 'number'],
      selectedUsers: {
        allFlag: false,
        ids: [],
      },
      checkedKey: 'numberPut',
      isImportDisabled: false,
      paymentType: StatusConstants.currencyType.payment,
      chargeType: StatusConstants.currencyType.charge,
      statusTimer: null,
      balance: null,
      timerId: null,
      selectedTicketUsers: {
        ticket: true,
        ticketCancel: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerList: 'customer/customerList',
      customerCount: 'customer/customerCount',
      childEventDetail: 'event/childEventDetail',
      userInfo: 'auth/infor',
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      useTicketFlag: 'event/useTicketFlag',
      hasSmsParentEvent: 'event/hasSmsParentEvent',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    importPath() {
      return `${this.userInfo?.id}_${this.subdomain}`;
    },
    dataTable() {
      return this.customerList.map(item => {
        return {
          ...item,
          number: item?.hasSwitchCpm ? breakLineWithComma((item?.number ? (item.number + ',') : '') + item?.numberPut) : item?.isCpm ? item?.number : item?.numberPut,
          name: endline(item?.nameSei + ' ' + item?.nameMei, 21),
          email: !this.hasSmsParentEvent ? item?.email : (item?.email && item?.mobilephone ? breakLineWithComma(item.email + ',' + item?.mobilephone) : (item?.email || item?.mobilephone)),
          balance: '¥' + formatLocalString(this.useTicketFlag ? item?.balanceTicket : item?.balance),
          loginDate: formatDateAndTime(item.loginDate),
        };
      });
    },
    isUploadCsvDisabled() {
      const disabledStatusList = [
        EventConstants.status.preparingPublic.value,
        EventConstants.status.expired.value,
        EventConstants.status.paused.value,
      ];
      return this.selectedUsers.allFlag || !!this.selectedUsers.ids.length || this.checkEventStatus(disabledStatusList) || this.isImportDisabled;
    },
    isChargeDisabled() {
      const disabledStatusList = [
        EventConstants.status.preparingPublic.value,
        EventConstants.status.expired.value,
        EventConstants.status.achieved.value,
        EventConstants.status.paused.value,
      ];
      return this.checkEventStatus(disabledStatusList) || this.isImportDisabled;
    },
    isPaymentDisabled() {
      const disabledStatusList = [
        EventConstants.status.preparingPublic.value,
        EventConstants.status.expired.value,
        EventConstants.status.paused.value,
      ];
      const isClientChildAndNotShopRole = this.isClientChild && !this.hasShopRole && this.selectedUsers.count > 1;
      return this.checkEventStatus(disabledStatusList) || this.isImportDisabled || isClientChildAndNotShopRole || this.isUseTicketFlagAndSelectedUsers;
    },
    currentChildCompany() {
      if (this.$permission.isOffice()) {
        return this.userInfo?.company?.parent?.id ? this.userInfo?.company : {}
      }

      return null;
    },
    totalUserSelected() {
      return this.selectedUsers?.count || 0;
    },
    isUseTicketFlagAndSelectedUsers() {
      const isTicketPaymentDisabled = (this.isClientChild && !this.hasShopRole && !this.selectedTicketUsers?.ticketCancel) || !this.selectedTicketUsers?.ticket;
      return this.useTicketFlag && (this.selectedUsers.count > 1 || (this.selectedUsers.count === 1 && isTicketPaymentDisabled));
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'number', name: 'user ID', tdClass: 'pre-line' },
        { key: 'name', name: 'お名前' },
        { key: 'email', name: this.hasSmsParentEvent ? 'メールアドレス/携帯電話番号' : 'メールアドレス', tdClass: 'pre-line' },
        { key: 'loginDate', name: '最終ログイン' },
        { key: 'balance', name: '残高' },
      ];
    },
  },
  watch: {
    childEventDetail(val) {
      if (Object.keys(val).length && !val.useCurrencyFlag) {
        this.$router.push({ name: '404' })
      }
    },
  },
  mixins: [modal, search, nav, download],
  components: {
    FormRow,
    TableWithCheckbox,
    PaginationLog,
    CurrencyModal,
  },
  mounted() {
    const importingCurrency = (JSON.parse(localStorage.getItem('IMPORTING_CURRENCY')) || {})[this.importPath];
    if (importingCurrency) {
      this.isImportDisabled = true;
      this.checkImportStatus(importingCurrency.id, importingCurrency.currencyType);
    } else {
      const loading = this.$loading.show();
      this.initForm();
      Promise.all([
        this.$store.dispatch('event/getChildEventDetail'),
        this.checkCanUseCurrency()
      ]).finally(() => {
        this.timerId = setInterval(() => this.checkCanUseCurrency(), 5000);
        this.$loading.clear(loading);
      })
    }
  },
  methods: {
    async checkCanUseCurrency() {
      let result = await this.$store.dispatch('currency/checkCanUseCurrency', this.childEventDetail?.id);
      if (!result) {
        this.isImportDisabled = true;
      } else {
        clearInterval(this.timerId);
        this.isImportDisabled = false;
      }
    },
    async handeClickShowModal(key) {
      const paymentType = key === StatusConstants.currencyType.payment;
      const isTicketPayment = paymentType && this.totalUserSelected === 1;
      if (this.selectedUsers.allFlag || this.selectedUsers.ids.length > 0) {
        this.type = key;
        if (this.isUseTicketFlagAndSelectedUsers && paymentType) return;
        if (this.useTicketFlag) {
          if (isTicketPayment) {
            const selectedId = this.customerList.find(item => item?.numberPut === this.selectedUsers.ids[0])?.id;
            const customerId = this.selectedUsers.allFlag ? this.customerList[0]?.id : selectedId;
            await this.$store.dispatch('currency/getPaymentTickets', { customerId: customerId });
          } else if (key === StatusConstants.currencyType.charge) {
            await this.$store.dispatch('currency/getChargeTickets');
          }
        } else if (this.selectedUsers.count === 1 && (this.type === this.paymentType)) {
          const number = this.customerList[0]?.hasSwitchCpm ? this.customerList[0]?.number : this.customerList[0]?.numberPut;
          const customerId = this.selectedUsers.allFlag ? number : this.selectedUsers.ids[0];
          const result = await this.$store.dispatch('currency/getBalanceManual', { customerId: customerId });
          if (result) {
            this.balance = this.isClientChild ? result?.company : result?.master;
          }
        }
        this.$refs.modal.showModal('currencyModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    handleSelectedItems(items) {
      this.selectedUsers = items;
      if (this.useTicketFlag) {
        const selectedItems = this.customerList.find(item => item?.numberPut === this.selectedUsers.ids[0]);
        const customer = this.selectedUsers.allFlag ? this.customerList[0] : selectedItems;
        this.selectedTicketUsers = {
          ticket: customer?.ticket,
          ticketCancel: customer?.ticketCancel,
        }
      }
    },
    uploadCsv() {
      if (!this.isUploadCsvDisabled) {
        this.$refs.csvInput.click();
      }
    },
    removeCsvFile() {
      this.$refs.csvInput.value = null;
    },
    onCsvFilePicked(event) {
      const action = this.useTicketFlag ? 'importTicketCurrencyCsv' : 'importCurrencyCsv';
      if (!this.isUploadCsvDisabled) {
        const files = event.target.files || event.dataTransfer.files;
        const file = files[0];
        if (checkFileSize(file) && checkCsvFile(file)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            const csvResult = e.target.result;
            const detectedEncoding = Encoding.detect(csvResult);
            if (FileConstants.ACCEPTED_CSV_ENCODEING.includes(detectedEncoding)) {
              const formData = new FormData();
              formData.append('input', file);
              const result = await this.$store.dispatch(`currency/${action}`, formData);
              if (result) {
                if (result.data.count) {
                  this.setImportingCurrency(result.data.id);
                  this.$message.showMessageSuccess(result.data.message);
                  this.checkImportStatus(result.data.id);
                } else {
                  this.removeImportingCurrency();
                  if (this.useTicketFlag) {
                    this.$message.showError('noPaymentedOrChargedTicketCurrency');
                  } else {
                    this.$message.showError('noPaymentedOrChargedCurrency');
                  }
                }
              }
            } else {
              this.$message.showError('unacceptedCharsetCsv');
            }
          }
          reader.readAsBinaryString(file);
        }
      } else {
        this.$message.showError('importing_csv');
      }
      this.removeCsvFile();
    },
    setImportingCurrency(id, currencyType) {
      this.isImportDisabled = true;
      const importingCurrency = JSON.parse(localStorage.getItem('IMPORTING_CURRENCY')) || {};
      importingCurrency[this.importPath] = { id, currencyType };
      localStorage.setItem('IMPORTING_CURRENCY', JSON.stringify(importingCurrency));
    },
    removeImportingCurrency() {
      this.isImportDisabled = false;
      const importingCurrency = JSON.parse(localStorage.getItem('IMPORTING_CURRENCY')) || {};
      delete importingCurrency[this.importPath];
      localStorage.setItem('IMPORTING_CURRENCY', JSON.stringify(importingCurrency));
    },
    checkImportStatus(id, currencyType, cancelType) {
      this.statusTimer = setTimeout(async () => {
        const result = await this.$store.dispatch('currency/getImportStatus', id);
        if (result) {
          switch (result.status) {
            case 0:
              this.checkImportStatus(id, currencyType, cancelType);
              break;
            case 1:
              if (currencyType) {
                this.$message.showSuccess(`import_${currencyType}_finished_success`);
              } else {
                this.$message.showSuccess('import_csv_finished_success');
              }
              this.$refs.pagination.resetPagination();
              if (this.useTicketFlag && result.errorData) this.downloadCsv(result.errorData, result.filename);
              this.removeImportingCurrency();
              this.resetCheckbox();
              break;
            case 2:
              if (result.count || this.useTicketFlag) this.$refs.pagination.resetPagination();
              if (result.errorData) this.downloadCsv(result.errorData, result.filename);
              if (currencyType) {
                this.$message.showSuccess(`import_${currencyType}_finished_fail`);
              } else {
                this.$message.showSuccess('import_csv_finished_fail');
              }
              this.removeImportingCurrency();
              this.resetCheckbox();
              break;
            case 3:
              if (!cancelType) this.downloadCsv(result.errorData, result.filename);
              if (currencyType) {
                this.$message.showError(`import_${currencyType}_fail`);
              }  else {
                this.$message.showError('import_currency_csv_fail');
              }
              if (this.useTicketFlag) this.$refs.pagination.resetPagination();
              this.removeImportingCurrency();
              this.resetCheckbox();
              break;
            default:
              break;
          }
        } else {
          this.removeImportingCurrency();
          this.resetCheckbox();
        }
      }, 10000);
    },
    checkEventStatus(statusList) {
      return statusList.includes(this.childEventDetail?.status?.id);
    },
    resetSearchCurrency() {
      this.resetSearch();
      this.resetCheckbox();
    },
    submitSearchCurrency() {
      this.search();
      this.resetCheckbox();
    },
    resetCheckbox() {
      if (this.totalUserSelected) {
        this.$refs.table.resetCheck();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.statusTimer);
  },
};
</script>
