<template>
<div>
  <Modal v-if="currencyModal">
    <template v-slot:headline>{{ isPaymentType ? '通貨を利用' : '通貨を付与' }}</template>
    <template v-slot:body>
      <FormRow>
        <template v-slot:label>種別を選択</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="listGroup" v-if="!isClientChild">
              <li
                v-for="item in parentTypes"
                :key="item.index"
                :class="{ 'listGroup-item': true, 'd-flex justify-content-between': !item.isLabelTicket }"
              >
                <label :class="{ 'form-radio': true, 'mb-0': item.isLabelTicket }">
                  <input
                    :class="{ 'form-radio-input': true, 'is-error': errors.type }"
                    type="radio"
                    name="type"
                    :value="item.value"
                    v-model="form.type"
                    @change="onChangeType(item)"
                  />
                  <span class="form-radio-label" :class="{ 'pre-line custom-form-label': hasGroupWithMaxLength }">{{ item.label }}</span>
                </label>
                <template v-if="item.isLabelTicket">
                  <TicketList :tickets="newCancelTickets"/>
                </template>
                <div :class="{ 'col-6': hasGroupAndPaymentType, 'col-7': !hasGroupAndPaymentType }" v-if="item.value === storeCurrencyType('parent')">
                  <SearchSelect
                    ref="searchSelectShop"
                    :isError="errors.shopId"
                    :disabled="item.isStoreOrCurrency"
                    :options="shopList"
                    :multiple="false"
                    :closeOnSelect="true"
                    :searchKeyMinLength="searchKeyLength"
                    :searchAction="searchAction"
                    :resetOptions="resetOptions"
                    :params="params"
                    :searchField="searchField"
                    @change-selection="handleSelectedShop"
                  />
                </div>
                <div class="col-7" v-if="item.isLabelCompany">
                  <div class="form-content-row">
                    <div class="form-select">
                      <select
                        :class="{ 'form-control form-select-input': true, 'is-error': errors.clientId }"
                        name="clientId"
                        :disabled="item.isStoreOrCurrency"
                        v-model="form.clientId"
                        @change="handleSelectedShop"
                      >
                        <option value="">選択してください</option>
                        <option v-for="company in newCompanyList" :key="company.id" :value="company.id">{{ company.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="listGroup" v-if="isClientChild">
              <li
                v-for="item in childTypes"
                :key="item.index"
                :class="{ 'listGroup-item': true, 'd-flex justify-content-between': !item.isLabelTicket && item.label !== problemLabel }"
              >
                <label :class="{ 'form-radio': true, 'mb-0': item.isLabelTicket }">
                  <input
                    :class="{ 'form-radio-input': true, 'is-error': errors.type }"
                    type="radio"
                    name="type"
                    :value="item.value"
                    v-model="form.type"
                    @change="onChangeType"
                  />
                  <span class="form-radio-label" :class="{ 'pre-line custom-form-label': hasGroupWithMaxLength }" v-if="isLabelClientChild(item)">{{ currentChildCompany.name }}{{ item.label }}</span>
                  <span class="form-radio-label" :class="{ 'pre-line custom-form-label': hasGroupWithMaxLength }" v-else>{{ item.label }}</span>
                </label>
                <p v-if="item.label === problemLabel">{{ currentChildCompany.name }}からの発行を行います。</p>
                <template v-if="item.isLabelTicket">
                  <TicketList :tickets="newCancelTickets"/>
                </template>
                <div :class="{ 'col-6': hasGroupAndPaymentType, 'col-7': !hasGroupAndPaymentType }" v-if="item.value === storeCurrencyType('child')">
                  <SearchSelect
                    ref="searchSelect"
                    singleLabel="name"
                    :isError="errors.shopId"
                    :disabled="!isCurrencyTypeStore('child')"
                    :options="shopList"
                    :multiple="false"
                    :closeOnSelect="true"
                    :searchKeyMinLength="searchKeyLength"
                    :searchAction="searchAction"
                    :resetOptions="resetOptions"
                    :params="params"
                    :searchField="searchField"
                    @change-selection="handleSelectedShop"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div class="form-content-row" v-if="isTicketShown">
            <label class="form-label">{{ ticketTitle }}</label>
            <div class="form-row listGroup-item" v-if="!!form.tickets.length">
              <div v-for="(item, index) in form.tickets" :key="item.id" class="form-content-row">
                <div class="form-group row">
                  <div class="form-group-item col-8 form-group-checked">
                    <label class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="isChecked"
                        v-model="form.tickets[index].isChecked"
                        @change="handleSelectedItem(item)"
                      />
                      <span :class="{ 'form-check-label form-check-card': true, 'is-error-checkbox': errors.isChecked }">
                        {{ item.name }}<br>{{ item.amountBreakdown | comma }}円ｘ{{ item.unitBreakdown }}枚
                      </span>
                    </label>
                  </div>
                  <div class="form-group-item col-4 form-group-selected">
                    <div class="form-content-row">
                      <div class="form-select">
                        <select
                          :class="{ 'form-control form-select-input': true, 'is-error': errors[`quantity${index}`] }"
                          :name="`quantity${index}`"
                          v-model="form.tickets[index].quantity"
                          :disabled="!form.tickets[index].isChecked"
                        >
                          <option :value="0">選択してください</option>
                          <option v-for="data in item.quantityList" :key="data.key">{{ data.value }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>一致するデータがありません。</div>
          </div>
          <div class="form-content-row" v-if="!isTicketShown">
            <NumericKeypad ref="keypad" :isError="errors.added || errors.amount" />
          </div>
        </template>
      </FormRow>
    </template>
    <template v-slot:footer>
      <ul class="listGrid justify-content-end">
        <li class="listGrid-item">
          <button class="btn btn-white" type="button" @click="handeClearModalAndDataForm">キャンセル</button>
        </li>
        <li class="listGrid-item">
          <ActionButton
            class="btn"
            :class="{ 'btn-red': isPaymentType, 'btn-main': !isPaymentType }"
            :handle-submit="submit"
            :disabled="isSubmitDisabled"
            :button-text="isPaymentType ? '利用する' : '付与する'"
          />
        </li>
      </ul>
    </template>
  </Modal>
  <Modal @close="closeModal('checkCurrencyModal')" v-if="checkCurrencyModal">
    <template v-slot:headline>{{ isPaymentType ? '通貨利用の確認' : '通貨付与の確認' }}</template>
    <template v-slot:body>
      <p class="description text-align-center">
        {{ ticketDescription }}
      </p>
      <ul class="listGrid justify-content-center">
        <li class="listGrid-item">
          <button class="btn btn-white" type="button" @click="handeClearModalAndDataForm">キャンセル</button>
        </li>
        <li class="listGrid-item">
          <ActionButton
            class="btn"
            :class="{ 'btn-red': isPaymentType, 'btn-main': !isPaymentType }"
            :handle-submit="handleCurrency"
            :button-text="isPaymentType ? '利用する' : '付与する'"
          />
        </li>
      </ul>
    </template>
  </Modal>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import { required, between, requiredIf } from 'vuelidate/lib/validators';
//components
import FormRow from '@/components/FormRow.vue';
import NumericKeypad from '@/components/NumericKeypad.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import Modal from '@/components/Modal.vue';
import TicketList from '@/components/TicketList.vue';
//mixins
import nav from '@/mixins/nav/currency';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import error from '@/mixins/plugin/error';
import company from '@/mixins/module/company';
//contants
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
//helpers
import { formatLocalString, convertNumberToArrayWithKey } from '@/helpers/formatData';

const amountWithMaxLength = 1000000;
export default {
  name: 'EventCurrenyModal',
  data() {
    return {
      convertNumberToArrayWithKey,
      selectedUsers: {
        allFlag: false,
        ids: [],
      },
      searchAction: 'shop/getShopList',
      searchActionCompany: 'client/getClientList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      resetOptionsCompany: 'client/RESET_CLIENT_LIST',
      searchField: 'name',
      params: {
        status: StatusConstants.shop.approved.value,
        validFlag: true,
        openFlag: true,
        directory: this.$route.params.directory
      },
      storeModule: 'customer',
      form: {},
      currencyModal: false,
      checkCurrencyModal: false,
      problemLabel: StatusConstants.currency.child.charge.problem.label,
      paymentStore: null
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      default: '',
    },
    target: {
      type: Object,
      required: true,
      default: () => {},
    },
    searchConditions: {
      type: Object,
      default: () => {},
    },
    currentChildCompany: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null
    },
    totalUserSelected: {
      type: Number,
    },
    balance: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      shopList: 'shop/shopList',
      companyList: 'client/clientList',
      hasShopRole: 'auth/hasShopRole',
      useTicketFlag: 'event/useTicketFlag',
      paymentTickets: 'currency/paymentTickets',
      chargeTickets: 'currency/chargeTickets',
      amountStoreList: 'currency/amountStoreList',
      childEventDetail: 'event/childEventDetail',
      customerList: 'customer/customerList',
    }),
    companyCurrencyType() {
      return StatusConstants.currency.parent.charge.company.value;
    },
    isPaymentType() {
      return this.type === StatusConstants.currencyType.payment;
    },
    isChargeType() {
      return this.type === StatusConstants.currencyType.charge;
    },
    newTickets() {
      const data = this.isChargeType ? this.chargeTickets : this.paymentTickets?.list;
      return this.formatTicketData(data);
    },
    newCancelTickets() {
      return (this.paymentTickets?.ticketCancel || []).map(e => {
        return {
          ...e,
          quantities: Math.floor(Number(e?.quantity) / Number(e?.unitBreakdown))
        }
      });
    },
    isCancelPayment() {
      return [StatusConstants.currency.parent.payment.cancel.value, StatusConstants.currency.child.payment.cancel.value].includes(this.form.type);
    },
    isCompanyCurrencyType() {
      return this.form.type === this.companyCurrencyType;
    },
    isSubmitDisabled() {
      const isClientChildNotShopRole = this.isClientChild && !this.hasShopRole;
      const isPaymentNotTicketData = this.isPaymentType && !this.newCancelTickets?.length;
      return this.isTicketShown && (!this.newTickets?.length || (isPaymentNotTicketData && isClientChildNotShopRole));
    },
    parentTypes() {
      return (this.typeData('parent') || []).map(e => {
        return {
          ...e,
          isLabelTicket: [StatusConstants.currency.parent.payment.cancel.value, StatusConstants.currency.child.payment.cancel.value].includes(e.value)
            && this.isTicketShown && this.isPaymentType && !!this.newCancelTickets?.length && e.label === 'TICKET取り消し',
          isStoreOrCurrency: (e.value === this.storeCurrencyType('parent')) ? !this.isCurrencyTypeStore('parent') : !this.isCompanyCurrencyType,
          isLabelCompany: e.label === StatusConstants.currency.parent.charge.company.label && e.value === this.companyCurrencyType,
        }
      }).filter(elm => {
        if (this.hasGroup) {
          return !this.isPaymentType ? elm.label !== StatusConstants.currency.parent.charge.store.label :
          elm.label !== StatusConstants.currency.parent.payment.manual.label && !elm.label.includes(StatusConstants.currency.parent.payment.cancel.label)
        } else if (this.childEventDetail?.hasSettingPointCharge && this.isPaymentType) {
          return !elm.label.includes(StatusConstants.currency.parent.payment.cancel.label);
        } else {
          return true;
        }
      });
    },
    childTypes() {
      return (this.typeData('child') || []).map(e => {
        return {
          ...e,
          isLabelTicket: [StatusConstants.currency.parent.payment.cancel.value, StatusConstants.currency.child.payment.cancel.value].includes(e.value)
            && this.isTicketShown && this.isPaymentType && !!this.newCancelTickets?.length && e.label === 'TICKET取り消し'
        }
      }).filter(elm => {
        if (this.hasGroup) {
          return !this.isPaymentType ? elm.label !== StatusConstants.currency.child.charge.store.label : !elm.label.includes(StatusConstants.currency.child.payment.cancel.label)
        } else if (this.childEventDetail?.hasSettingPointCharge && this.isPaymentType) {
          return !elm.label.includes(StatusConstants.currency.child.payment.cancel.label);
        } else {
          return true;
        }
      });
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    convertedTicketForm() {
      return (this.form?.tickets || []).map(e => {
        return {
          ...e,
          quantity: Number(e?.quantity),
        }
      });
    },
    isCancelType() {
      return [StatusConstants.currency.parent.payment.cancel.value, StatusConstants.currency.child.payment.cancel.value].includes(this.form.type);
    },
    isTicketShown() {
      return this.useTicketFlag && ((this.isPaymentType && this.target.count === 1) || this.isChargeType);
    },
    totalCheckedTicket() {
      return (this.form?.tickets || []).filter(item => item.isChecked === true)?.length;
    },
    hasOneUserAndManyTickets() {
      return this.totalUserSelected === 1 && this.totalCheckedTicket >= 2;
    },
    ticketDescription() {
      if (this.hasOneUserAndManyTickets && this.isChargeType) return this.totalCheckedTicket + '個付与しますがよろしいですか？';
      if (this.isTicketShown && this.isPaymentType) return `全部で${this.ticketQuantity}枚利用しますがよろしいですか？`;
      const ticketDes = (this.totalCheckedTicket === 1) ? '人に付与しますがよろしいですか？' : `人に${this.totalCheckedTicket}個付与しますがよろしいですか？`;
      const currencyDes = this.isPaymentType ? '件利用しますがよろしいですか？' : '件付与しますがよろしいですか？';
      return this.totalUserSelected + (this.isTicketShown ? ticketDes : currencyDes);
    },
    quantity() {
      return this.isPaymentType ? 'paymentQuantity' : 'chargeQuantity';
    },
    newCompanyList() {
      return (this.companyList || []).map((e, index) => {
        return {
          ...e,
          name: `${index+1}：${e.name}`
        }
      })
    },
    ticketTitle() {
      return this.isChargeType ? '発行するTICKETと口数を選択' : '消費するTICKETと枚数を選択';
    },
    ticketQuantity() {
      return (this.form?.tickets || []).reduce((total, item) => total + Number(item?.quantity || 0), 0);
    },
    hasGroupAndPaymentType() {
      return this.hasGroup && this.totalUserSelected === 1 && this.isPaymentType;
    },
    hasGroupWithMaxLength() {
      return this.hasGroupAndPaymentType && (this.paymentStore > amountWithMaxLength);
    },
    isShowPaymentStore() {
      return this.paymentStore !== null && this.hasGroupAndPaymentType;
    }
  },
  mixins: [modal, search, error, company, nav],
  components: {
    NumericKeypad,
    SearchSelect,
    FormRow,
    Modal,
    TicketList,
  },
  watch: {
    target(val) {
      this.form = {
        ...this.form,
        target: val.ids,
        allFlag: val.allFlag,
      };
    },
    newTickets(val) {
      if (val) {
        this.form.tickets = val;
      }
    },
    'form.type'(newVal, oldVal) {
      const cancelPaymentType= this.isClientChild ? StatusConstants.currency.child.payment.cancel.value : StatusConstants.currency.parent.payment.cancel.value;
      if (this.isPaymentType) {
        if (![oldVal, newVal].includes(cancelPaymentType)) return;
        if (this.isCancelType) {
          this.form.tickets = this.formatTicketData(this.newCancelTickets);
        } else {
          this.form.tickets = this.formatTicketData(this.paymentTickets?.list);
        }
      }
    },
    async currencyModal(val) {
      if (val && this.hasGroupAndPaymentType) {
        const ids = this.target?.allFlag ? this.customerList[0]?.numberPut : this.target?.ids[0];
        await this.$store.dispatch('currency/getAmountStoreList', { number: ids });
      }
    }
  },
  validations() {
    const form = {
      type: { selectRequired: required },
      tickets: {},
    };
    const type = !this.isClientChild ? 'parent' : 'child';
    if (this.form.type === this.storeCurrencyType(type)) {
      form.shopId = { selectRequired: required };
    } else if (this.form.type === this.companyCurrencyType && this.isChargeType) {
      form.clientId = { selectRequired: required };
    }
    if (!this.isTicketShown) {
      if (this.isPaymentType) {
        if (this.isShowPaymentStore && this.form.type === this.storeCurrencyType(type)) {
          form.amount = { required, minAmount: between(1, this.paymentStore) };
        } else if (this.isCancelPayment && this.balance >= 0) {
          form.amount = { required, minAmount: between(1, this.balance) };
        } else {
          form.amount = { required, between: between(1, 999999) };
        }
      } else if (this.isChargeType) {
        form.added = { required, between: between(1, 999999) };
      }
    }
    if (this.isTicketShown && !!this.newTickets?.length) {
      form.isChecked = {
        selectRequired: requiredIf(function(eventItem) {
          const hasCheckedItem = eventItem?.tickets?.some(item => item.isChecked);
          return !hasCheckedItem;
        })
      }
      form[this.quantity] = {
        selectRequired: requiredIf(function(eventItem) {
          const hasCheckedItem = eventItem?.tickets?.some(item => item.isChecked);
          if (!hasCheckedItem) return;
          const filteredInvalidIndex = eventItem?.tickets?.reduce((array, item, index) => {
            if (item.isChecked && !Number(item.quantity)) {
              array.push(index);
            }
            array?.map(e => this.errors[`quantity${e}`] = true);
            return array;
          }, []);
          return !!filteredInvalidIndex?.length;
        })
      }
    }
    return { form }
  },
  methods: {
    typeData(type) {
      let data = Object.values(StatusConstants.currency[type][this.type]);
      return data.filter(e => {
        const hasTicketCount = !this.isTicketShown ? this.target.count > 1 : (this.target.count !== 1 || !this.newCancelTickets?.length);
        const hasShopRoleAndStoreLabel = (!this.hasShopRole && e.label === StatusConstants.currency.parent[this.type].store.label);
        const hasSelectedUsersAndCancelLabel = (this.isPaymentType && hasTicketCount && e.label === StatusConstants.currency[type][this.type].cancel.label);
        return !hasShopRoleAndStoreLabel && !hasSelectedUsersAndCancelLabel;
      }).map(item => {
        const newItem = { ...item };
        const newCancelLabel = newItem.label + '(最大金額：￥' + formatLocalString(this.balance) + ')';
        if (newItem.label === StatusConstants.currency.parent.payment.cancel.label && (this.totalUserSelected === 1 || this.isTicketShown)) {
          newItem.label = this.isTicketShown ? 'TICKET取り消し' : newCancelLabel;
        } else if (newItem.label === StatusConstants.currency.parent.payment.store.label && this.hasGroupAndPaymentType) {
          const pointStore = this.isShowPaymentStore ? ' (利用可能金額：' + formatLocalString(this.paymentStore) + '円)' : '';
          newItem.label = newItem.label + (this.hasGroupAndPaymentType && this.paymentStore > amountWithMaxLength ? '\n' : '') + pointStore;
        }
        return newItem;
      });
    },
    storeCurrencyType(type) {
      return StatusConstants.currency[type][this.type]?.store.value;
    },
    isCurrencyTypeStore(type) {
      return this.form.type === this.storeCurrencyType(type);
    },
    isLabelClientChild(item) {
      return item.label === StatusConstants.currency.parent.payment.cancel.label || item.label === this.problemLabel;
    },
    isLabelTicket(item) {
      return [StatusConstants.currency.parent.payment.cancel.value, StatusConstants.currency.child.payment.cancel.value].includes(item.value)
        && this.isTicketShown && this.isPaymentType && !!this.newCancelTickets?.length && item.label === 'TICKET取り消し';
    },
    formatTicketData(data) {
      return (data || []).map(e => {
        return {
          ...e,
          isChecked: false,
          quantityList: this.convertNumberToArrayWithKey(this.isChargeType ? this.formatTicketQuantity(e) : e.quantity),
          quantity: 0,
        }
      }).filter(item => item.quantityList?.length > 0);
    },
    initForm() {
      this.form = {
        target: this.target?.ids || [],
        allFlag: this.target?.allFlag || false,
        searchCondition: this.searchConditions || {},
        type: '',
        clientId: '',
        shopId: '',
        tickets: [],
      }
    },
    handleSelectedShop(item) {
      if (this.form.type === this.companyCurrencyType) {
        this.form.clientId = item.target.value;
      } else {
        this.form.shopId = item?.id;
        if (this.hasGroupAndPaymentType) {
          if (!item?.id) {
            this.paymentStore = null;
          } else {
            (this.amountStoreList || []).forEach(e => {
              if (Object.prototype.hasOwnProperty.call(e, item?.id)) {
                this.paymentStore = e[item?.id];
              }
            })
          }
        }
      }
    },
    handeClearModalAndDataForm() {
      this.paymentStore = null;
      this.initForm();
      this.closeModal('checkCurrencyModal');
      this.closeModal('currencyModal');
    },
    handleCloseModal() {
      this.closeModal('checkCurrencyModal');
      this.closeModal('currencyModal');
    },
    formatData() {
      if (this.isPaymentType) {
        this.form = {
          ...this.form,
          amount: this.$refs.keypad?.number,
        }
      } else if (this.isChargeType) {
        this.form = {
          ...this.form,
          added: this.$refs.keypad?.number,
          clientId: !this.isClientChild ? this.form?.clientId : this.infor?.company?.id
        }
      }
      if (this.isTicketShown) {
        delete this.form?.added;
        delete this.form?.amount;
      }
    },
    formatTicketQuantity(data) {
      const budget = (data?.budgetAmount - data?.totalBudgetUsed) / data?.purchaseAmount;
      const limitPerCharge = data?.limitPerCharge || 99;
      return data?.budgetAmount ? Math.min((budget / this.totalUserSelected), limitPerCharge) : Math.min(99, limitPerCharge);
    },
    async onChangeType(item) {
      this.errors.shopId = false;
      this.errors.clientId = false;
      if (item?.isLabelCompany) {
        await this.$store.dispatch('client/getClientList', {
          eventId: this.eventId,
          type: 'children',
          limit: 9999,
        });
      }
      this.resetSearchSelect();
    },
    resetSearchSelect() {
      if (!!this.form.clientId && this.$refs?.searchSelectShop?.length) {
        this.$refs.searchSelectShop[0].resetSelection();
        this.form.clientId = '';
        this.form.shopId = '';
      } else if (!this.form.clientId && this.$refs?.searchSelectShop?.length) {
        this.$refs.searchSelectShop[0].resetSelection();
        this.form.shopId = '';
      } else if (this.$refs?.searchSelect?.length) {
        this.$refs.searchSelect[0].resetSelection();
        this.form.shopId = '';
      }
    },
    submit() {
      this.formatData();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'currency');
      } else {
        if (this.isTicketShown) {
          this.form = { ...this.form, tickets: this.convertedTicketForm };
        } else {
          delete this.form?.tickets;
        }
        if (this.totalUserSelected > 1 || this.hasOneUserAndManyTickets) {
          this.showModal('checkCurrencyModal');
        } else {
          this.handleCurrency();
        }
      }
    },
    async handleCurrency() {
      this.handleCloseModal();
      let result;
      if (this.isPaymentType) {
        const action = this.isTicketShown ? 'createPaymentTicketCurrency' : 'createPaymentCurrency';
        result = await this.$store.dispatch(`currency/${action}`, this.form);
      } else if (this.isChargeType) {
        const action = this.isTicketShown ? 'createChargeTicketCurrency' : 'createChargeCurrency';
        result = await this.$store.dispatch(`currency/${action}`, this.form);
      }
      if (result) {
        const cancelType = (this.isPaymentType && this.isCancelType) ? this.form.type : null;
        this.initForm();
        this.handeClearModalAndDataForm();
        this.$emit('setImportingCurrency', result.data.id, this.type);
        this.$message.showMessageSuccess(result.data.message);
        this.$emit('checkImportStatus', result.data.id, this.type, cancelType);
      }
    },
    handleSelectedItem(item) {
      if (!item.isChecked) item.quantity = 0;
    }
  },
}
</script>

<style scoped>
.custom-form-label {
  line-height: 1.5;
}
</style>
